/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { FC } from "react";
import { ContactUs } from "../components/ContactUs";
import { JobListingCardCharacterArtist } from "../components/JobListingCardCharacterArtist";
import { JobListingCardEmpty } from "../components/JobListingCardEmpty";
import Layout from "../components/Layout/Layout";

const Careers: FC = () => {
  return (
    <Layout
      currentPage="/careers"
      pathname="/careers"
      title="Beartwigs - Careers"
    >
      <section id="hero-careers">
        <div className="hero-container">
          <h1
            css={css`
              color: white;
              text-shadow: 0px 4px 18px #000000;
              margin-bottom: 40px;
              font-family: Palanquin;
            `}
          >
            Work with us!
          </h1>
        </div>
      </section>
      <section id="careers">
        <div className="container">
          <div className="section-title">
            <h2>Careers</h2>
            <h3>
              Our currently available <span>positions</span>
            </h3>
            <p>
              If you don't see your type of position listed but still think you
              would be an amazing fit, don't hesitate to contact us, we are
              always open for possibilities!
            </p>
          </div>
          <div>
            <JobListingCardEmpty />
          </div>
        </div>
      </section>
      <ContactUs />
    </Layout>
  );
};

export default Careers;
