/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { FC } from "react";

export const JobListingCardEmpty: FC = () => {
  return (
    <div
      className="row d-flex justify-content-center"
      css={css`
        margin-bottom: 20px;
        padding: 40px;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        border: 1px solid #f9f9f9;
        border: 1px dotted black;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 650px;
      `}
    >
      <div
        className="col-lg-12"
        css={css`
          text-align: center;
        `}
      >
        <h1>We currently don't have any open positions</h1>
        <p>
          However, we are always open for standing applications and we will get
          back to you once there's an available position that fits
        </p>
      </div>
    </div>
  );
};
