/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { FC, useEffect, useState } from "react";
import { FaEnvelope } from "react-icons/fa";

export const ContactUs: FC = () => {
  const [generalEmail, setGeneralEmail] = useState("-");
  const [careersEmail, setCareersEmail] = useState("-");
  const [businessEmail, setBusinessEmail] = useState("-");

  useEffect(() => {
    setGeneralEmail(atob("Y29udGFjdEBiZWFydHdpZ3MuY29t"));
    setCareersEmail(atob("am9ic0BiZWFydHdpZ3MuY29t"));
    setBusinessEmail(atob("Y2VvQGJlYXJ0d2lncy5jb20="));
  }, []);

  return (
    <section id="faq" className="faq">
      <div className="container">
        <div className="section-title">
          <h2>Contact</h2>
          <h3>Contact Us</h3>
          <p>The best way to reach us is through one of our mails</p>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-2">
            <div className="about">
              <div className="icon-box">
                <div className="icon">
                  <FaEnvelope style={{ fontSize: "28px", color: "#8fc04e" }} />
                </div>
                <div
                  css={css`
                    padding-left: 10px;
                  `}
                >
                  <h4
                    css={css`
                      padding: 0 0 0 70px;
                      font-size: 24px;
                      font-weight: 600;
                      margin-bottom: 6px;
                      color: #353535;
                    `}
                  >
                    General
                  </h4>
                  <p
                    css={css`
                      padding: 0 0 0 70px;
                      margin-bottom: 0;
                      font-size: 16px;
                      color: #686868;
                    `}
                  >
                    {generalEmail}
                  </p>
                </div>
              </div>
              <div className="icon-box">
                <div className="icon">
                  <FaEnvelope style={{ fontSize: "28px", color: "#8fc04e" }} />
                </div>
                <div
                  css={css`
                    padding-left: 10px;
                  `}
                >
                  <h4
                    css={css`
                      padding: 0 0 0 70px;
                      font-size: 24px;
                      font-weight: 600;
                      margin-bottom: 6px;
                      color: #353535;
                    `}
                  >
                    Careers
                  </h4>
                  <p
                    css={css`
                      padding: 0 0 0 70px;
                      margin-bottom: 0;
                      font-size: 16px;
                      color: #686868;
                    `}
                  >
                    {careersEmail}
                  </p>
                </div>
              </div>
              <div className="icon-box">
                <div className="icon">
                  <FaEnvelope style={{ fontSize: "28px", color: "#8fc04e" }} />
                </div>
                <div
                  css={css`
                    padding-left: 10px;
                  `}
                >
                  <h4
                    css={css`
                      padding: 0 0 0 70px;
                      font-size: 24px;
                      font-weight: 600;
                      margin-bottom: 6px;
                      color: #353535;
                    `}
                  >
                    Business
                  </h4>
                  <p
                    css={css`
                      padding: 0 0 0 70px;
                      margin-bottom: 0;
                      font-size: 16px;
                      color: #686868;
                    `}
                  >
                    {businessEmail}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
